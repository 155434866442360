<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思受邀为党员律师讲解司法鉴定实务专题学习活动！</div>
      <p class="time">2017.03.24</p>
      <p class="space_content">
        2017年3月24日下午，北京盈科（厦门）律师事务所党支部组织2017年度第一期党员业务学习活动。特邀来自福建历思司法鉴定所曾志胤主任、法医鉴定室曾建晟主任共同为党员律师讲解司法鉴定实务。</p>
      <p class="space_content">
        福建历思司法鉴定所是国内司法鉴定管理体制改革后成立的第一批独立的第三方司法鉴定机构。历经十年发展，成为2000平方米大型物证中心、福建省内执业项目齐全的综合性司法鉴定机构。历思目前拥有9大司法鉴定执业资质：环境物证鉴定、法医物证鉴定（DNA亲权鉴定、个体识别）、法医临床鉴定、法医毒物鉴定、法医病理鉴定、海事物证鉴定、声像资料鉴定、文书司法鉴定、痕迹司法鉴定等。
      </p>
      <p class="space_content">
        讲座首先由曾建晟主任围绕“法医类鉴定”做分享。以自己多年的从医经验，详细讲解了法医临床鉴定、法医病理鉴定、法医毒物鉴定和法医物证鉴定（DNA亲权鉴定、个体识别）等方面的鉴定标准和案例，让律师们对于工作中常见伤情鉴定、医疗纠纷、遗产继承等涉及的医学专业知识等有了清晰的理解。
      </p>
      <p class="space_content">
        在讲座的第二部分，由曾志胤主任做实务分享。曾主任多年来专长于司法鉴定领域，尤其是文书司法鉴定、痕迹司法鉴定与环境物证鉴定，创建国家环保部推荐的环境损害鉴定评估机构并主持大型综合物证中心工作，组织专家团队承办多起重特大环境事件、重特大交通事故、重特大金融案件的鉴定工作。在2015年受福建省环境保护厅委托，作为主要专家起草了地方标准《福建省环境损害鉴定评估通用规范》（征求意见稿），并于2016年8月通过省环保厅、省技术监督局组织的《福建省生态环境损害鉴定评估系列标准开题论证会》，通过立项认证；列入福建省2016年地方环保标准、省级环保科技计划项目。
      </p>
      <p class="space_content">
        围绕着文书及痕迹检验、文书形成时间、声像资料鉴定、环境物证鉴定等领域，曾主任做了详细的讲解，针对律师提出的业务中存在的疑问，曾主任通过多个样本分析报告的展示，一一做出解答，并于律师做了深入的交流。
      </p>
      <p class="space_content">
        律师在实务中时常会遇到对于证据真伪认定、证据损害复原的情况，通过对司法鉴定热点问题的学习，让参会律师受益匪浅。在今后工作中能够充分运用司法鉴定这一专业技术手段，提高综合业务能力，更好的维护当事人的合法权益。
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/7'"><p class="ellipsis1">历思通过检验检测机构资质认定（CMA认证）</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/10'"><p class="ellipsis1">笔迹专家秀绝技 上电视准确“破案”</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
